import {
    elementComponent,
    Extension,
    ICompositeComponent,
    IElementTransformProps,
    IMiddlewareExtender,
    TComponent,
    truncateDisplayName
} from '@lardy/core';
import { DomPopmotionConfig } from 'popmotion-pose';
import { ComponentType } from 'react';
import { IDomPopmotionStaticProps } from './interfaces';
import { PopmotionEntity } from './popmotionEntity';

class PosedFragment extends Extension {
    public posed<PCFG extends { [index: string]: DomPopmotionConfig }>(
        popmotionConfig: PCFG
    ) {
        const cfg = Object.keys(popmotionConfig).reduce((entities, key) => {
            entities[key] = new PopmotionEntity(key, popmotionConfig[key]);

            return entities;
        }, {} as { [index: string]: PopmotionEntity });

        this.getSelf().define(
            'elementRootTransform',
            ({ element, name }: IElementTransformProps) => {
                let Element: ComponentType<any>;

                if (cfg[name] !== undefined) {
                    Element = cfg[name].getElement(element);
                } else {
                    Element = elementComponent(element);
                }

                Element.displayName = truncateDisplayName(
                    `${element}.${name}`,
                    'PosedElement'
                );

                return Element;
            }
        );

        return (this.getSelf() as unknown) as this extends TComponent<
            infer P,
            infer S,
            infer OP,
            infer EX,
            infer SX,
            infer XP
        >
            ? TComponent<P, S | keyof PCFG, OP, EX, SX & {
                [K in keyof PCFG ]: {
                    pose?: Exclude<keyof PCFG[K], IDomPopmotionStaticProps>
                }
            }, XP>
            : this;
    }
}

const constructor = (self: ICompositeComponent) => {
    return new PosedFragment(self);
};

(constructor as any).__middlewareRole = 'extender';

const posed = constructor as IMiddlewareExtender<typeof constructor>;

export { posed };
