import { elementComponent } from '@lardy/core';
import { DomPopmotionConfig } from 'popmotion-pose';
import { ComponentType } from 'react';
import posed from 'react-pose';
import { IHydratedPopMotionEntity } from './interfaces';

export class PopmotionEntity implements IHydratedPopMotionEntity {
    private cfg: DomPopmotionConfig = {};
    private elements: { [index: string]: ComponentType<any> } = {};

    public constructor(name: string, cfg: DomPopmotionConfig) {
        this.cfg = cfg;
    }

    public getElement(elementName: string) {
        if (posed[elementName] === undefined/*  || typeof window === 'undefined' */) {
            return elementComponent(elementName);
        }

        if (this.elements[elementName] === undefined) {
            this.elements[elementName] = posed[elementName](this.cfg);
        }

        return this.elements[elementName];
    }
}
